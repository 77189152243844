<template>
  <div>
    <div class="header-page">
      <h4>{{ $route.query.name }}</h4>
      <div class="mt-1" id="inventory-summery">
        Quantity : {{ quantity | numeral("0,0") }} , Total Price :
        {{ total_price | numeral("0,0.00") }}
      </div>
      <!-- <div class="badge-inventory">ไม่รวมจำนวนติดลบ</div> -->
    </div>

    <b-tabs class="mt-4" v-model="tabsIndex">
      <b-tab title="Inventory Log">
        <div class="bg-white p-2 p-lg-3">
          <b-input-group class="mb-2">
            <b-form-input
              type="text"
              id="header-search-bar"
              class="search-bar"
              @keyup.enter="handleSearch"
              placeholder="Search Name, Barcode "
              v-model="filterLog.search"
            >
            </b-form-input>
            <b-input-group-append is-text class="mr-2">
              <b-iconstack font-scale="2" type="submit" @click="handleSearch">
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-iconstack>
            </b-input-group-append>
            <b-button @click.prevent="sidebarFilter" class="btn-filter mr-2">
              <font-awesome-icon icon="filter" class="d-md-none" />
              <span class="d-none d-md-block">
                <font-awesome-icon icon="filter" class="pointer" />
                <span class="btn-text">Filter</span>
              </span>
            </b-button>
          </b-input-group>

          <Table
            class="inventory-table"
            :filter="filterLog"
            :fields="fieldsLog"
            :items="itemsLog"
            :isBusy="isBusy"
            :rows="rowsLog"
            :showingTo="showingTo"
            @filterPage="filterPage"
          />
        </div>
      </b-tab>
    </b-tabs>
    <!-- <ModalEditStock ref="modal-edit-stock" @getData="getInventoryLog" /> -->

    <ModalExportByDocument
      ref="modalExportByDocument"
      :branchId="filter.branch_id"
    />
    <!-- <ModalImportInventory ref="modalImportInventory" @getData="getInventory" /> -->
    <FooterAction routePath="/branch-management" hideSubmit />
    <FilterSidebar
      ref="filter-sidebar"
      :filter="filterLog"
      @clearFilter="clearFilter"
      @submit="onSubmitFilter"
    />
  </div>
</template>

<script>
import InputDropdownCheckbox from "@/components/inputs/InputDropdownCheckbox";

import UploadFileV2 from "@/components/inputs/UploadFileV2";
import Table from "./Table";
// import ModalEditStock from "./components/ModalEditStock.vue";
import ModalExportByDocument from "./components/ModalExportByDocument.vue";
import FilterSidebar from "./components/Filter.vue";
// import ModalImportInventory from "./components/ModalImportInventory.vue";

export default {
  components: {
    Table,
    UploadFileV2,
    // ModalEditStock,
    InputDropdownCheckbox,
    ModalExportByDocument,
    FilterSidebar,
    // ModalImportInventory,
  },
  data() {
    return {
      branchSelectAll: false,
      branchSelected: [],
      branchSelectOption: [],
      typeSelectAll: false,
      typeSelected: [],
      productSelect: true,
      typeSelectOption: [],

      filter: {
        search: "",
        page: 1,

        take: 10,
        branch_id: Number(this.$route.params.id),
        product_type: ["ZFIN", "ZLPR", "ZPACK", "ZSAM", "ZSER"],
      },
      filterLog: {
        search: "",
        page: 1,
        take: 10,
        log_type: [],
        branch_id: Number(this.$route.params.id),
      },
      isBusy: false,
      rows: 0,
      quantity: 0,
      total_price: 0,
      rowsLog: 0,
      showing: 1,
      showingTo: 10,
      tabsIndex: 0,
      productType: [
        { text: "ZFIN", value: "ZFIN" },
        { text: "ZLPR", value: "ZLPR" },
        { text: "ZPACK", value: "ZPACK" },
        { text: "ZSAM", value: "ZSAM" },
        { text: "ZSER", value: "ZSER" },
      ],

      subfields: [
        { key: "name", label: "Stock", class: "w-2" },
        { key: "stock", label: "Amount", class: "w-2" },
      ],
      fieldsLog: [
        {
          key: "name",
          label: "Name / Barcode (SKU)",
          tdClass: "text-left p-3",
        },
        {
          key: "serial",
          label: "Serial",
        },
        {
          key: "stock_type_name",
          label: "Stock",
        },

        {
          key: "log_type_name",
          label: "Type",
        },

        {
          key: "stock",
          label: "Quantity",
        },
        {
          key: "available",
          label: "Available",
        },
        {
          key: "update_price",
          label: "Price",
        },
        {
          key: "update_cost",
          label: "Cost",
        },
        {
          key: "invoice_no",
          label: "Invoice No. / Document No.",
        },
        {
          key: "note",
          label: "Note",
          thStyle: { minWidth: "100px" },
        },
        {
          key: "create_date",
          label: "Date",
        },
      ],

      itemsLog: [],
      items: [],
      subItems: [],

      modalShow: false,
      filename: "",
      form: {
        excel_file: "",
        branch_id: this.$route.params.id,
      },
      isDisable: false,
      subTotal: {},
      isBusy2: false,
    };
  },
  mounted() {
    this.getAllInventoryData();
    // this.getInventoryLog();
  },
  watch: {
    // tabsIndex(val) {
    //   if (val) {
    //     this.getInventoryLog();
    //   } else {
    //     this.getInventory();
    //   }
    //   return (this.filter.search = ""), (this.filterLog.search = "");
    // },
  },
  methods: {
    getAllInventoryData() {
      this.getInventoryLog();
      // this.getInventoryActivity();
    },
    handleOnFilter() {
      //   if (this.tabsIndex == 0) this.getInventory();
      this.getInventoryLog();
    },

    async getInventoryLog() {
      this.isBusy = true;
      const response = await this.axios.post(
        `/Inventory/GetInventorylog`,
        this.filterLog
      );

      this.itemsLog = response.data.detail.data;
      this.rowsLog = response.data.detail.count;
      this.quantity = response.data.detail.quantity
      this.total_price = response.data.detail.total_price
      this.isBusy = false;
    },

    autoSearch() {
      this.getInventoryLog();
    },
    handleSearch() {
      this.getInventoryLog();
    },
    clearModal() {
      this.modalShow = false;
      this.form.excel_file = "";
      this.filename = "";
    },
    getStockList() {},
    clearFilter() {
      this.productSelect = true;
      this.filterLog = {
        search: "",
        page: 1,
        take: 10,
        stock_type:[],
        log_type: [],
        branch_id: Number(this.$route.params.id),
      };
    },
    sidebarFilter() {
      this.$refs["filter-sidebar"].show();
    },
    filterPage() {
      this.getInventoryLog();
    },
    onSubmitFilter(filter) {
      this.getInventoryLog();
    },
  },
};
</script>

<style lang="scss" scoped>
#inventory-summery {
  position: relative;
  font-weight: normal;
  max-width: fit-content;
  &::after {
    position: absolute;
    border-radius: 5px;
    padding: 0px 5px;
    background: red;
    color: white;
    content: "ไม่รวมจำนวนติดลบ";
    bottom: -75%;
    white-space: nowrap;
    left: 0;
    font-size: 10px;
  }
}
::v-deep .width-btn {
  min-width: 7rem;
}
::v-deep .dropdown-toggle.btn-secondary {
  background-color: #fff;
  color: var(--primary-color);
  border-color: var(--primary-color);
}
::v-deep .dropdown-item:active {
  text-decoration: none;
  background-color: #fff;
  color: var(--primary-color) !important;
}
::v-deep .dropdown-menu {
  width: fit-content;
  li:hover {
    text-decoration: none;
    background-color: #fff;
    color: var(--primary-color) !important;
  }
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
}
::v-deep .filter-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  gap: 1rem;
}

.header-page {
  color: black;
  font-weight: bold;
  h4 {
    font-size: 25px;
    // &::after {
    // }
  }
}
::v-deep .dropdown-menu li:hover {
  color: #000000 !important;
}
// ::v-deep .inventory-table table th {
//   width: 16.67%;
// }
</style>
