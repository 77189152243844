<template>
  <SideBarFilter
    ref="sidebarFilter"
    :filter="cloneFilter"
    :hideStatusFilter="false"
    :hideSearchBar="true"
    placeholder="Search Name, Barcode (SKU)"
    @searchAll="submit"
    @clearFilter="clearFilter"
  >
    <template v-slot:filter-option>
      <InputDropdownCheckbox
        textFloat="Movement Type"
        name="movement-type"
        :valueSelectedAll="movementSelect"
        :value="cloneFilter.log_type"
        :options="movementOptions"
        valueField="value"
        textField="text"
        @onDataChangeCheckAll="(val) => (movementSelect = val)"
        @onDataChange="(val) => (cloneFilter.log_type = val)"
        class="mb-3"
      />
      <InputDropdownCheckbox
        textFloat="Stock"
        name="stock"
        :valueSelectedAll="stockSelect"
        :value="cloneFilter.stock_type"
        :options="stockOptions"
        valueField="value"
        textField="text"
        @onDataChangeCheckAll="(val) => (stockSelect = val)"
        @onDataChange="(val) => (cloneFilter.stock_type = val)"
      />
    </template>
  </SideBarFilter>
</template>

<script>
import SideBarFilter from "@/components/SideBarFilter";
export default {
  name: "Filter",
  props: {
    filter: {
      required: true,
      default: () => {},
      type: Object,
    },
  },
  components: { SideBarFilter },
  data() {
    return {
      movementOptions: [],
      movementSelect: false,
      stockOptions: [],
      stockSelect: false,
    };
  },
  computed: {
    cloneFilter() {
      return this.filter;
    },
  },
  methods: {
    async getMovement() {
      const data = await this.$store.dispatch("getMovementType");

      this.movementOptions = data;
    },
    async getStock() {
      const data = await this.$store.dispatch("getStockList");
      this.stockOptions = data;
    },
    async show() {
      this.$bus.$emit("showLoading");
      const promiseFunctions = [
        () => this.getMovement(),
        () => this.getStock(),
      ];
      console.log(this.$helper.customPromiseAll(promiseFunctions));
      // await this.getMovement();
      // await this.getStock();
      this.$refs.sidebarFilter.show();
      this.$bus.$emit("hideLoading");
    },
    hide() {
      this.$refs.sidebarFilter.hide();
    },
    clearFilter() {
      this.$emit("clearFilter");
    },
    submit() {
      this.$emit("submit", this.cloneFilter);
    },
  },
};
</script>

<style></style>
